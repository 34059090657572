import { environment } from '../../../environments/environment';

export class CommonAssetsUtil {
  private static COMMON_ASSETS_BASE_URL = environment.commonAssetsUrl;
  private static IMAGE_BASE_URL = `${this.COMMON_ASSETS_BASE_URL}/images/`;
  private static PICTOS_BASE_URL = `${this.IMAGE_BASE_URL}pictos/`;

  static getCardImageUrl(filenameWithExtension: string): string {
    return `${this.IMAGE_BASE_URL}cards/${filenameWithExtension}`;
  }

  static getPictoOtherUrl(filenameWithExtension: string): string {
    return `${this.PICTOS_BASE_URL}other/${filenameWithExtension}`;
  }

  static getPictoWorksUrl(filenameWithExtension: string): string {
    return `${this.PICTOS_BASE_URL}works/${filenameWithExtension}`;
  }

  static getParcoursUrl(filenameWithExtension: string): string {
    return `${this.PICTOS_BASE_URL}parcours/${filenameWithExtension}`;
  }
}
